<template>
  <form @submit.prevent="onsubmit()">
    <div class="row">
      <va-notification class="flex md10" v-if="currState=='success'" color="success">
        {{ currMsg }}
      </va-notification>
      <va-notification class="flex md10" v-if="currState=='error'" color="danger">
        {{ currMsg }}
      </va-notification>
    </div>

    <br/>

    <va-input
      v-model="userName"
      :label="$t('auth.userName')"
      :error="!!userNameErrors.length"
      :error-messages="userNameErrors"
    />

    <va-input
      v-model="email"
      type="email"
      :label="$t('auth.email')"
      :error="!!emailErrors.length"
      :error-messages="emailErrors"
    />

    <div class="auth-layout__options d-flex align--center justify--space-between">
      {{ $t('auth.registerPwdInfo') }}
    </div>

    <va-input
      v-model="registerPassword"
      type="password"
      :label="$t('auth.registerPassword')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />

    <div class="auth-layout__options d-flex align--center justify--space-between">
      <va-checkbox
        v-model="agreedToTerms"
        class="mb-0"
        :error="!!agreedToTermsErrors.length"
        :errorMessages="agreedToTermsErrors"
      >
        <template slot="label">
          {{ $t('auth.agree') }}
        </template>
      </va-checkbox>
    </div>

    <div v-if="isChecking" class="d-flex justify--center mt-3">
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#000000"
      />
    </div>
    <div v-else class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">{{ $t('auth.sign_up') }}</va-button>
    </div>
  </form>
</template>

<script>
import { LoopingRhombusesSpinner } from 'epic-spinners'

export default {
  name: 'signup',
  components: {
    LoopingRhombusesSpinner,
  },
  data () {
    return {
      userName: '',
      email: '',
      registerPassword: '',
      agreedToTerms: false,
      userNameErrors: [],
      emailErrors: [],
      passwordErrors: [],
      agreedToTermsErrors: [],
      currState: '',
      currMsg: '',
      isChecking: false,
      timeoutID: '',
    }
  },
  methods: {
    onsubmit () {
      this.userNameErrors = this.userName ? [] : [this.$t('auth.errUserNameRequired')]
      this.emailErrors = this.email ? [] : [this.$t('auth.errEmailRequired')]
      this.passwordErrors = this.registerPassword ? [] : [this.$t('auth.errPasswordRequired')]
      this.agreedToTermsErrors = this.agreedToTerms ? [] : [this.$t('auth.errAggreeToTerms')]
      if (!this.formReady) {
        return
      }
      this.isChecking = true

      this.timeoutID = setTimeout(this.loginTimeout, 5000)

      this.$userauth.registerUser({
        username: this.userName,
        email: this.email,
        password: this.registerPassword,
        regURL: window.location.href,
        emailSubject: this.$t('auth.emailSubjectRegister'),
        emailGreeting: this.$t('auth.emailGreeting'),
        emailText: this.$t('auth.emailTxtRegister1'),
        emailFooter: this.$t('auth.emailTxtRegister2'),
      })
        .then(result => {
          clearTimeout(this.timeoutID)
          this.isChecking = false
          if (result.userData.status === 'OK') {
            this.currMsg = this.$t('auth.regMailSent')
            this.currState = 'success'
          } else {
            this.currMsg = this.$t('auth.regAccountExists')
            this.currState = 'error'
          }

          this.resetRegisterForm()
        })
        .catch(err => {
          console.log(err)
          this.setRegFailMessage()
        })
        .catch(err => {
          console.log(err)
          this.setRegFailMessage()
        })
    },
    loginTimeout () {
      this.isChecking = false
      this.currMsg = this.$t('auth.errTimeout')
      this.currState = 'error'
    },
    setRegFailMessage () {
      clearTimeout(this.timeoutID)
      this.currMsg = this.$t('auth.errRegisterFailed')
      this.currState = 'error'
      this.isChecking = false
      this.resetRegisterForm()
    },
    resetRegisterForm () {
      this.userName = ''
      this.email = ''
      this.registerPassword = ''
      this.agreedToTerms = false
    },
  },
  computed: {
    formReady () {
      return !(this.emailErrors.length || this.passwordErrors.length || this.agreedToTermsErrors.length)
    },
  },
}
</script>

<style lang="scss">
</style>
